import React, { useState } from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/vertical.css';
import {
  Title,
  TechniqueTitle,
  BackButton,
  BackArrow
} from "../styles/Case.styles";

const JuneMeetSliderComponent = ({ toBack, hasBackground, handleBackClick }) => {
  const [sliderContent] = useState([
    { image: "./img/projects/big/junemeetbg.png" },
    // { image: "../img/projects/big/junemeetbg4.jpg" },
    // { image: "./img/projects/big/junemeetbg1.png" },
    { image: "./img/projects/big/junemeetbg2.png" },
    { image: "../img/projects/big/junemeetbg3.png" }
  ]);

  return (
   <>
    <Slider className="slider-wrapper" autoplay={2000}>
      {sliderContent.map((item, index) => (
        <div
          key={index}
          className="slider-content"
          style={{width:'100%', height:'100%', backgroundImage: `url('${item.image}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }}
        >
       </div>
      ))}
    </Slider>
     <div className='herocaptionbg'>
     <BackButton
       className='backdetail'
       onClick={handleBackClick}
       toBack={toBack ? 1 : 0}
       hasBackground={hasBackground ? 1 : 0}
     >
       <BackArrow src="./img/back.png" alt="Back to Projects" />
       <span>Back </span>
     </BackButton>
     <Title>Aviha Group (Pvt) Ltd Quarterly Meeting and Staff Get Together - June 2024</Title>
     <TechniqueTitle>
       An unforgettable evening of camaraderie and celebration, where Aviha's team came together to honor achievements and share laughter.
     </TechniqueTitle>
   </div>

 </>
    
       
  );
};

export default JuneMeetSliderComponent;
