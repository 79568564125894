import React, { Component, createRef, Fragment } from "react";
import {
    Hero,
    Title,
    TechniqueTitle,
    ClientTag,
    CaseWrapper,
    BackButton,
    BackArrow
} from "../styles/Case.styles";
import './mainhero.css';

import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Accomplish from '../components/Accomplish';
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2 } from "../styles/effect.styles";

export const ScrollTop = ({ children, location }) => {
    React.useEffect(() => window.scrollTo(0, 0), [location])
    return children
}

class Case extends Component {
    constructor(props) {
        super(props);
        this.introRef = createRef();

        this.state = {
            toBack: false,
            introTop: 0,
            hasBackground: false,
            animateCase: ""
        };
    }

    componentDidUpdate() {
        if (this.state.toBack) {
            setTimeout(() => {
                this.props.setNavBackground(false);
                this.props.history.push("/work");
            }, 400);
        }
        if (this.state.animateCase) {
            setTimeout(() => {
                this.props.setNavBackground(false);

                this.props.history.push(this.state.animateCase);
                window.scrollTo(0, 0);
            }, 400);
        }
    }


    render() {

        return (
            <Fragment>
                <Efectr />
                <Efectr1 />
                <Efectr2 />
                <ScrollTop>
                    <CaseWrapper>
                        <Reveal effect="fadeIn">
                        <div className='septbg' style={{ backgroundImage: "url('./img/projects/big/septmeetbg.png')" }}>
                        <div className='herocaption' style={{ marginBottom: '-233px' }}>
                                    <BackButton
                                        className='backdetail'
                                        onClick={() => this.setState({ toBack: true, hasBackground: false })}
                                        toBack={this.state.toBack}
                                        hasBackground={this.state.hasBackground}
                                    >
                                        <BackArrow src="./img/back.png" alt="Back to Projects" />
                                        <span>Back </span>
                                    </BackButton>
                                    <Title>Aviha Group (Pvt) Ltd Third Quarterly Meeting and Staff Get Together - September 2024</Title>
                                    <TechniqueTitle>
                                        The Aviha Group of Companies (Pvt) Ltd. celebrated its third-quarter accomplishments with grandeur and excitement during the Third Quarterly Meeting and Fiesta, held at the Spice Room, Shangri-La on 25th September 2024.
                                    </TechniqueTitle>
                                </div>
                            </div>


                        </Reveal>

                        <section className='container-fluid' id='detailproject' style={{ marginTop: '250px' }}>
                            <div className='row m-2-hor'>
                                <div className='col-md-8'>
                                    <p className='content'>
                                        Each quarter, Mr. Priyankara Sumanapalage, Chairman of the Aviha Group, alongside the heads of business units, heads of departments and employees from its subsidiaries—Colombo Bartender and Barista School, Colombo Caregiver and Health Science School, JobDaddy.lk, and DocMart Pharmaceuticals—come together to recognize their collective achievements, explore future opportunities, and foster camaraderie across the organization.
                                    </p>

                                    <p className='content'>
                                        As per tradition, Mr. Priyankara Sumanapalage delivered a warm welcome to the attendees, followed by insightful presentations from the heads of business units and departments, showcasing their key accomplishments, the challenges they had navigated, and the strategic plans they have laid out for the coming quarter.
                                    </p>
                                    <p className='content'>

                                        The event was not only about business; it was a true celebration of the Aviha family. The gathering honored new associates and recognized birthdays, making it a personal and festive affair. This quarter's celebration was more extravagant than ever, with a series of vibrant staff performances, featuring comedy, singing, drama, and dance acts. The ever-popular Dark Shine dance troupe once again mesmerized the audience with their stunning Jazz, Western, Bollywood and traditional dance performances, adding a sparkling touch to the night’s festivities.
                                    </p>
                                    <p className='content'>

                                        One of the highlights of the evening was the introduction of the Pinnacle Awards, a prestigious new accolade created to honor those who consistently go above and beyond to elevate their departments to new heights. This marked the second major award introduced by the Aviha Group, following the launch of the Chairman’s Awards in the previous quarter.
                                    </p>
                                    <p className='content'>

                                        The evening came to a spectacular close with a lavish dinner, exclusive drinks from a bar specially designed for the fiesta, a fun-filled 360° video booth, and live DJ music, ensuring that the celebration continued well into the night.
                                    </p>
                                </div>
                                <div className='col-md-4 sticky'>
                                    <ul className='detailproject'>
                                        <li>
                                            <span className='tile'>Categories:</span>
                                            <span>Staff Get-Together</span>
                                        </li>
                                        <li>
                                            <span className='tile'>Held By:</span>
                                            <span>Aviha Group</span>
                                        </li>
                                        <li>
                                            <span className='tile'>Completed:</span>
                                            <span>September 25, 2024</span>
                                        </li>
                                        <li>
                                            <span className='tile'>Event type:</span>
                                            <span>Staff Meeting</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-md-12'>
                                    <div className='tags'>
                                        <span className='heading'>
                                            Tags :
                                        </span>
                                        <span className='content'>
                                            StaffGetTogether
                                        </span>
                                        <span className='content'>
                                            QuarterlyMeeting
                                        </span>
                                        <span className='content'>
                                            AwardingCeremony
                                        </span>


                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='share'>
                                        <span className='heading'>
                                            Share :
                                        </span>
                                        <span className='content'>
                                            <a href="https://www.facebook.com/p/Aviha-Group-100070033276546/" target="_blank" rel="noopener noreferrer">
                                                <i className="fa fa-facebook-f"></i>
                                            </a>
                                        </span>
                                        <span className='content'>
                                            <a href="https://www.linkedin.com/company/aviha-group" target="_blank" rel="noopener noreferrer">
                                                <i className="fa fa-linkedin"></i>
                                            </a>
                                        </span>
                                        <span className='content'>
                                            <a href="#" target="_blank" rel="noopener noreferrer">
                                                <i className="fa fa-twitter"></i>
                                            </a>
                                        </span>
                                        <span className='content'>
                                            <a href="https://www.instagram.com/avihagroup?igsh=MWQ3eXkyNWRmYXUwOQ==" target="_blank" rel="noopener noreferrer">
                                                <i className="fa  fa-instagram"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </CaseWrapper>

                </ScrollTop>

                {/* <Reveal effect="fadeInUp">
        <Accomplish className='pt-0'/>
      </Reveal> */}

                <Footer />

            </Fragment>
        );
    }
}

export default withRouter(Case);
