import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
} from "../styles/Case.styles";
import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2 } from "../styles/effect.styles";

// ScrollTop component to scroll to the top on location change
const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location]);
  return children;
};

class Case extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/work");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }

  render() {
    return (
      <Fragment>
        <Efectr />
        <Efectr1 />
        <Efectr2 />
        <ScrollTop location={this.props.location}>
          <CaseWrapper>
            <Reveal effect="fadeIn">
              <Hero className='mainhero'>
                <video
                  autoPlay
                  loop
                  muted
                  style={{ width: '100%', objectFit: 'cover' }}
                >
                  <source src="./img/projects/big/pirith.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className='herocaptionbgvideo'>
                  <BackButton
                    className='backdetail'
                    onClick={() => this.setState({ toBack: true, hasBackground: false })}
                    toBack={this.state.toBack}
                    hasBackground={this.state.hasBackground}
                  >
                    <BackArrow src="./img/back.png" alt="Back to Projects" />
                    <span>Back </span>
                  </BackButton>
                  <Title>Pirith Ceremony 2024</Title>
                  <TechniqueTitle>
                  A Pirith ceremony was held at the Walukarama office to bless the Aviha Group and its subsidiaries. 
                  </TechniqueTitle>               
                  </div>
              </Hero>
            </Reveal>

            <section className="container-fluid detailproject-section" id="detailprojectvid">
            <div className='row m-2-hor'>
                <div className='col-md-8'>
                  <p className='content'>
                  To bless the Chairman of the Aviha Group of Companies, Mr. Priyankara Sumanapalage, and the staff of its subsidiaries—Colombo Bartender and Barista School, Colombo Caregiver and Health Science School, JobDaddy.LK, and DocMart Pharmaceuticals—a Pirith ceremony was held at the Walukarama office premises on July 24, 2024. 



                  </p>
                  </div>
                <div className='col-md-4 sticky'>
                  <ul className='detailprojectvid'>
                    <li>
                      <span className='tile'>Categories:</span>
                      <span>Religious/Cultural Event</span>
                    </li>
                    <li>
                      <span className='tile'>Held By:</span>
                      <span>Aviha Group</span>
                    </li>
                    <li>
                      <span className='tile'>Completed:</span>
                      <span>July 24, 2024</span>
                    </li>
                    <li>
                      <span className='tile'>Event type:</span>
                      <span>Pirith Ceromany</span>
                    </li>
                  </ul>
                </div>
                <div className='col-md-12'>
                  <div className='tags'>
                    <span className='heading'>Tags :</span>
                    <span className='content'>PirithCeromany</span>
                    
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='share'>
                    <span className='heading'>Share :</span>
                    <span className='content'>
                      <a href="https://www.facebook.com/watch/?v=1703218233759522&ref=sharing" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-facebook-f"></i>
                      </a>
                    </span>
                    <span className='content'>
                      <a href="https://www.linkedin.com/company/aviha-group" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </span>
                    <span className='content'>
                      <a href="#" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </span>
                    <span className='content'>
                      <a href="https://www.instagram.com/avihagroup?igsh=MWQ3eXkyNWRmYXUwOQ==" target="_blank" rel="noopener noreferrer">
                        <i className="fa  fa-instagram"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </CaseWrapper>
        </ScrollTop>

        {/* <Reveal effect="fadeInUp">
          <Accomplish className='pt-0' />
        </Reveal> */}

        <Footer />
      </Fragment>
    );
  }
}

export default withRouter(Case);
