import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomSlide from './CustomSlide';

export default class Responsive extends Component {

  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 5000,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div className='slick slickproject'>
        <Slider {...settings}>
        <CustomSlide
            src="./img/projects/septmeet.png"
            name="Third Quaterly Meeting - September 2024"
            uri="/detailcase7"
          />

          <CustomSlide
            src="./img/projects/pirith.png"
            name="Pirith Ceremony 2024"
            uri="/detailcase6"
          />

          <CustomSlide
            src="./img/projects/junemeet.png"
            name="Second Quaterly Meeting - June 2024"
            uri="/detailcase5"
          />
          <CustomSlide
            src="./img/projects/future.png"
            name="Future Minds Expo 2024"
            uri="/detailcase1"
          />
          <CustomSlide
            src="./img/projects/surya.png"
            name="Surya Mangalya 2024"
            uri="/detailcase2"
          />
          <CustomSlide
            src="./img/projects/iftar.png"
            name="Iftar Celebration 2024"
            uri="/detailcase3"
          />
          <CustomSlide
            src="./img/projects/meet.png"
            name="First Quaterly Meeting - March 2024"
            uri="/detailcase4"
          />
         
        </Slider>
      </div>
    );
  }
}
