import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const CustomSlide = ({ src, name, uri }) => {
  const [coord, setCoords] = useState();
  const history = useHistory();

  const handleCaseSwap = (e) => {
    if (e.nativeEvent.x < coord + 15 && e.nativeEvent.x > coord - 15) {
      setTimeout(() => {
        history.push(uri);
      }, 600);
    }
  };

  return (
    <div
      className="itm"
      onMouseDown={(e) => setCoords(e.nativeEvent.x)}
      onMouseUp={handleCaseSwap}
    >
      <div className="bg">
        <img src={src} className="img-fluid" alt={name} />
      </div>
      <div className="desc" style={{marginBottom:'20px'}}>
        <div className="name">{name}</div>
      </div>
 
      <div className="icon">
        <span onClick={() => history.push(uri)}>View Project</span>
      </div>
    </div>
  );
};

export default CustomSlide;
