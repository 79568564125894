import React, { Component, Fragment, createRef } from "react";
import { withRouter } from 'react-router-dom';
import {
  CaseWrapper,
} from "../styles/Case.styles";
import {
  Hero
} from "../styles/Case.styles";
import Reveal from 'react-reveal/Reveal';
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2 } from "../styles/effect.styles";
import JuneMeetSliderComponent from "../components/JuneMeetSlider";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

class Case extends Component {

  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  handleBackClick = () => {
    this.setState({ toBack: true, hasBackground: false });
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/work");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }

  render() {

    return (
      <Fragment>
        <Efectr />
        <Efectr1 />
        <Efectr2 />
        <ScrollTop>
          <CaseWrapper>
            <Reveal effect="fadeIn">
              <Hero className='mainhero'>
                <JuneMeetSliderComponent
                  toBack={this.state.toBack}
                  hasBackground={this.state.hasBackground}
                  handleBackClick={this.handleBackClick}
                />
              </Hero>

            </Reveal>

            <section className='container-fluid' id='detailproject' style={{ marginTop: '300px' }}>
              <div className='row m-2-hor'>
                <div className='col-md-8'>
                  <p className='content'>The second quarterly meeting of the Aviha Group of Companies for the year 2024 was held on June 26th at the Shangri-La Hotel, Colombo. Employees from companies under the Aviha Group—Colombo Bartender and Barista School, Colombo Caregiver School, and Jobdaddy.lk—participated with enthusiasm and excitement in this highly anticipated event.</p>
                  <p className='content'>
                    The evening was filled with music, mesmerising dances, and delightful delicacies. Guests, dressed in elegant casual attire, added colour and glamour to the occasion.
                  </p>
                  <p className='content'>
                    The event kicked off with a warm welcome speech by the Group Chairman, Mr. Priyankara Sumanapalage, who greeted all the staff. This was followed by presentations detailing the company's history, remarkable progress, goals, and plans for achieving them.
                  </p>
                  <p className='content'>
                    The highlight of the meeting was when Chairman Mr. Sumanapalage ceremonially launched the Aviha Group of Companies' website. The website aims to enhance the company’s client relationships and expand its products and services to a broader audience.
                  </p>

                  <p className='content'>
                    The Aviha Group's continued success has facilitated the addition of more than thirty new employees to the Aviha family. These new team members were introduced to the Chairman and other employees at the event. Additionally, birthdays of employees in June were celebrated.
                  </p>
                  <p className='content'>

                    The second quarterly meeting of 2024 was a resounding success, and the Aviha family is now focused on achieving even greater milestones in the third quarter.
                  </p>
                </div>
                <div className='col-md-4 sticky'>
                  <ul className='detailproject'>
                    <li>
                      <span className='tile'>Categories:</span>
                      <span>Staff Get-Together</span>
                    </li>
                    <li>
                      <span className='tile'>Held By:</span>
                      <span>Aviha Group</span>
                    </li>
                    <li>
                      <span className='tile'>Completed:</span>
                      <span>June 26, 2024</span>
                    </li>
                    <li>
                      <span className='tile'>Event type:</span>
                      <span>Staff Meeting</span>
                    </li>
                  </ul>
                </div>
                <div className='col-md-12'>
                  <div className='tags'>
                    <span className='heading'>
                      Tags :
                    </span>
                    <span className='content'>
                      StaffGetTogether
                    </span>
                    <span className='content'>
                      QuarterlyMeeting
                    </span>
                    <span className='content'>
                      AwardingCeremony
                    </span>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='share'>
                    <span className='heading'>
                      Share :
                    </span>
                    <span className='content'>
                      <a href="https://www.facebook.com/share/p/U3UwhUyieFpJe5Xs/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-facebook-f"></i>
                      </a>
                    </span>
                    <span className='content'>
                      <a href="https://www.linkedin.com/company/aviha-group" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </span>
                    <span className='content'>
                      <a href="#" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </span>
                    <span className='content'>
                      <a href="https://www.instagram.com/avihagroup?igsh=MWQ3eXkyNWRmYXUwOQ==" target="_blank" rel="noopener noreferrer">
                        <i className="fa  fa-instagram"></i>
                      </a>
                    </span>
                  </div>
                </div>
                {/* <div className='col-md-12 mt-4'>
                  <video
                    className='w-100'
                    autoPlay
                    loop
                    muted
                    style={{ borderRadius: '8px' }}
                  >
                    <source src="./img/projects/big/junemeeting.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div> */}
              </div>
            </section>
          </CaseWrapper>
        </ScrollTop>

        <Footer />
      </Fragment>
    );
  }
}

export default withRouter(Case);
